/* styles.css */

.bg-red {
  background-color: red;
}

.bg-blue {
  background-color: blue;
}

.bg-indomable-blue {
    background-color: #7EA3CC;
}

.bg-indomable-bluestrong {
    background-color: #255C99;
}

.bg-indomable-black {
    background-color: #262626;
}

.bg-indomable-cream {
    background-color: #F4F0ED;
}